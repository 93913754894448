
import { getOssUrl } from '@/libs/utils';
import { copySelectTextContents } from '@mobro/libs';
import { Iobject } from '@mobro/libs/es/type';
import { request } from '@mobro/request';
import { Message } from 'element-ui';
import { Component, Vue } from 'vue-property-decorator';
import ArticlePreview from '../Preview/ArticlePreview.vue';

@Component({
    components: { ArticlePreview },
})
export default class ArticleBriefList extends Vue {
    articleBriefList = [];
    showPreview = false;
    newsItemList = [];
    dateStr = '';
    posterUrl = '';
    posterWhiteUrl = '';
    sentenceTxt = '';
    picture = '';
    morning = '';
    activityId = '';
    private pagination: Iobject = {
        current: 1,
        pageSize: 7,
        total: 0,
    };

    created() {
        this.getNewsItemList();
    }

    getNewsItemList() {
        request({
            url: '/api/article/news/list',
            params: {
                current: this.pagination.current,
                pageSize: this.pagination.pageSize,
                brief: 0,
            },
        }).then(res => {
            this.pagination = {
                current: res.current,
                pageSize: res.pageSize,
                total: res.total,
            };
            this.articleBriefList = (res.data || []).map((x: Iobject) => {
                return {
                    ...x,
                    ...x?.newsDesp,
                    sentenceTxt: (x?.newsDesp?.sentence?.desp ?? []).map((x: Iobject) => x.title)?.join('\n'),
                    newsTitle: `今日要闻 | ${x?.newsDesp?.title ?? x?.newsName}`,
                };
            });
        });
    }

    onPreview(x: Iobject) {
        this.showPreview = true;
        this.newsItemList = (x?.news?.desp || []).map((n: Iobject) => {
            return {
                ...n,
                ...n.newsDesp,
            };
        });

        this.posterUrl = getOssUrl(x.poster);
        this.posterWhiteUrl = getOssUrl(
            x.posterWhite ||
                x.poster.replace(/([0-9]{8})/, ($: string, $1: string) => {
                    return $1 + '-white';
                }),
        );
        this.dateStr = x?.newsDesp?.title;
        this.sentenceTxt = x?.sentenceTxt;
        this.picture = getOssUrl(x.picture);
        this.morning = getOssUrl(x.morning);
        this.activityId = x.activityId;
    }

    tableRowClassName(x: Iobject) {
        const { rowIndex } = x;
        if (rowIndex === 1) {
            return 'warning-row';
        } else if (rowIndex === 3) {
            return 'success-row';
        }
        return '';
    }

    async onNewsIdClick(row: Iobject) {
        await copySelectTextContents(row.newsId);
        Message.success('复制成功！');
    }

    onPageCurrentChange(x: number) {
        this.pagination.current = x;
        this.getNewsItemList();
    }
}
